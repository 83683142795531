// src/App.js
import './App.css'
import { useState, useEffect } from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import axios from 'axios';
import { MdDownload, MdShare, MdOutlineSearch } from "react-icons/md";

const Pica = require('pica/dist/pica');
const pica = new Pica();

const App = () => {
  const [file, setFile] = useState(null);
  const [inputImage, setInputImage] = useState(null);
  const [prompt, setPrompt] = useState("");
  const [inpaintedImages, setInpaintedImages] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);

  // set GPU API provider
  const API_PROVIDER = {
    LOCAL: 'https://somudro.ngrok.io',
    POPLAR: 'https://api.poplarml.com/infer',
  }
  const apiProvider = API_PROVIDER.POPLAR;

  // hook for converting inpainted base64 images to urls
  useEffect(() => {
    const urls = inpaintedImages.map(base64ToUrl);
    setImageUrls(urls);
    return () => {
      urls.forEach(URL.revokeObjectURL);
    };
  }, [inpaintedImages]);

  // on input photo file change
  const onChange = async e => {
    setFile(e.target.files[0]);
    const imageBase64 = await resizeImage(e.target.files[0]);
    setInputImage(imageBase64);
  };

  // function to resize image
  const resizeImage = async (file) => {
    // create image object from uploaded file
    const img = new Image();
    img.src = URL.createObjectURL(file);

    // wait for image to load
    await new Promise(resolve => {
      img.onload = resolve;
    });

    // calculate new image dimensions
    let { width, height } = img;
    console.log("orig width: " + width + ", orig height: " + height);
    if (width < height) {
      const scale = 512 / width;
      width = 512;
      height = Math.ceil(height * scale);
      height = height - (height % 8);
    } else {
      const scale = 512 / height;
      height = 512;
      width = Math.ceil(width * scale);
      width = width - (width % 8);
    }
    console.log("new width: " + width + ", new height: " + height);

    // resize image
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const result = await pica.resize(img, canvas);

    // create base64 string and remove header
    const resizedImageDataUrl = result.toDataURL('image/jpeg');
    return resizedImageDataUrl;
  }

  // function to handle GPU server API call
  const generateInpainting = async (apiUrl, apiInputs) => {
    await axios.post(apiUrl, apiInputs)
      .then(res => {
        // display inpainted image(s) if they exist, else show alert with message from server
        if (res.data.hasOwnProperty('inpainted')) {
          setInpaintedImages(res.data.inpainted);
        } else {
          alert(res.data.message);
        }
      })
      .catch(err => {
        alert(err);
      });
  }

  // handler for save image button
  const handleSave = () => {
    if (inpaintedImages.length >= 1) {
      const link = document.createElement('a');
      link.href = inpaintedImages[0];
      link.download = 'dressed-up.png';
      link.click();
    }
  };

  // handler for web share API
  const handleShareClick = async () => {
    if (imageUrls.length >= 1) {
      try {
        const response = await fetch(imageUrls[0]);
        const blob = await response.blob();
        const file = new File([blob], 'myimage.png', { type: 'image/png' });
        await navigator.share({
          title: 'My shared image',
          files: [file],
        });
        console.log('Successfully shared');
      } catch (error) {
        console.error('Error sharing:', error);
      }
    }
  };

  // handler for search button
  const handleSearch = () => {
    if (prompt) {
      window.open(`https://www.google.com/search?q=${prompt}`, '_blank');
    }
  };

  // function to convert base 64 image to URL
  const base64ToUrl = (base64Data) => {
    const parts = base64Data.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    const blob = new Blob([uInt8Array], { type: contentType });
    return URL.createObjectURL(blob);
  };

  // main function to handle form submission
  const onSubmit = async e => {
    e.preventDefault();

    // ignore button if already generating
    if (isGenerating) {
      return;
    }
    // check if file is an image
    if (!file || !file.name.match(/\.(jpg|JPG|jpeg|JPEG|png|PNG|gif|GIF)$/)) {
      alert('Please upload an image file');
      return;
    }
    // check if image has been loaded
    if (!inputImage) {
      alert('Please wait for image to load');
    }
    // check if prompt is empty
    if (!e.target.prompt.value) {
      alert('Please enter a prompt');
      return;
    }

    // set generating to be true and set/log prompt
    setIsGenerating(true);
    setPrompt(e.target.prompt.value);
    console.log(e.target.prompt.value);

    // remove header from base64 string
    const imageBase64 = inputImage.split(',')[1];

    // create dictionary of prompt and image
    const modelInputs = {
      'prompt': e.target.prompt.value,
      'image': imageBase64,
      'mask_threshold': 0.25,
      'guidance_scale': 7.5,
      'num_inference_steps': 50,
      'num_images_per_prompt': 1,
      'negative_prompt': "ugly, tiling, poorly drawn hands, poorly drawn feet, poorly drawn face, out of frame, " +
                         "mutation, mutated, extra limbs, extra legs, extra arms, disfigured, " +
                         "deformed, cross-eye, body out of frame, blurry, bad art, bad anatomy, blurred, text, watermark, grainy"
    };

    // send inpainting request to API
    if (apiProvider === API_PROVIDER.LOCAL) {
      await generateInpainting(API_PROVIDER.LOCAL, modelInputs)
    } else if (apiProvider === API_PROVIDER.POPLAR) {
      const apiInputs = {
        "apiKey": "gU80NBVOxG3uMWkC9g3hvg",
        "modelId": "inpainterv2",
        "modelInput": modelInputs
      }
      await generateInpainting(API_PROVIDER.POPLAR, apiInputs)
    }
    setIsGenerating(false);
  };

  return (
    <div className='App'>
      <h1 className='center-wrapper'>Dressing Room</h1>
      <form onSubmit={onSubmit}>
        <div className='center-wrapper'>
          <input
            type='file'
            className='custom-file-input'
            id='customFile'
            onChange={onChange}
          />
          <input type="text" name="prompt" placeholder="Describe new clothing style..."></input>
        </div>
        <input
          type='submit'
          value={isGenerating ? 'Generating...' : 'Make new clothes!'}
          className={isGenerating ? 'new-clothes-btn loading' : 'new-clothes-btn'}
        />
        <div className='center-wrapper'>
          <BeatLoader color='#552f2f' loading={isGenerating} size={20}/>
        </div>
        <div>
          <h2 className='center-wrapper'>Instructions</h2>
          <ul>
            <li>Upload or take a photo of yourself (see guidelines below)</li>
            <li>Enter a description for new clothes you'd like to "try on", e.g. "fluffy winter jacket." Feel free to be as descriptive as you'd like.</li>
            <li>Keep generating new images! They are random and different each time. You can also try new descriptions.</li>
          </ul>
        </div>
        <div>
          <h3 className='center-wrapper'>Photo upload guidelines</h3>
          <ul>
            <li>Since the app works by replacing existing clothing, choose a photo with not too much skin showing. Full-sleeve/full-length clothing is best.</li>
            <li>Make sure the photo doesn't have other clothes in the background. A clear uncluttered background is best.</li>
            <li>If your first photo doesn't work well, try another.</li>
          </ul>
        </div>
      </form>
      <div className='center-wrapper'>
        {inputImage && <img src={inputImage} alt="input"></img>}
        {!inputImage && <svg className='empty-room' width="512" height="512"><rect width="512" height="512"/></svg>}
        {inpaintedImages.length >= 1 && imageUrls.length >= 1 && (
          <>
            <img src={inpaintedImages[0]} alt="inpainted"></img>
            <div className='icon-button-group'>
              <button className='icon-button' onClick={handleSave} data-tooltip='Save Image'><MdDownload size='3em' /></button>
              {navigator.share && <button className='icon-button' onClick={handleShareClick} data-tooltip='Share Image'><MdShare size='3em'/></button>}
              <button className='icon-button' onClick={handleSearch} data-tooltip='Search for Clothes'><MdOutlineSearch size='3em' /></button>
            </div>
          </>
        )}
        {inpaintedImages.length < 1 && <svg className='empty-room' width="512" height="512"><rect width="512" height="512"/></svg>}
      </div>
    </div>
  );
};

export default App;